import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
// import Navbar from "./components/Navbar";
// import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
// import About from "./components/About/About";
// import Projects from "./components/Projects/Projects";
// import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import Login from "./components/Login/Login";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { BiHomeAlt } from "react-icons/bi";
// import { Link } from 'react-router-dom'
// import image from "./Assets/login-background.jpg"; 

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  // return (
  //   <Router>
  //     <Preloader load={load} />
  //     <div className="App" id={load ? "no-scroll" : "scroll"}>
  //       <Navbar />
  //       <ScrollToTop />
  //       <Routes>
  //         <Route path="/home" element={<Home />} />
  //         <Route path="/project" element={<Projects />} />
  //         <Route path="/about" element={<About />} />
  //         <Route path="/resume" element={<Resume />} />
  //         <Route path="*" element={<Navigate to="/"/>} />
  //       </Routes>
  //       <Footer />
  //     </div>
  //   </Router>
  // );

    // return (
    //   <div className="App">
    //     <h1>Marine Mammals</h1>
    //     <Router>
    //       <Routes>
    //         <Route path="/home">
    //           <Router>
    //             <Preloader load={load} />
    //             <div className="Wrapper" id={load ? "no-scroll" : "scroll"}>
    //               <Navbar />
    //               <ScrollToTop />
    //               <Routes>
    //                 <Route path="/home" element={<Home />} />
    //                 <Route path="/project" element={<Projects />} />
    //                 <Route path="/about" element={<About />} />
    //                 <Route path="/resume" element={<Resume />} />
    //                 <Route path="*" element={<Navigate to="/"/>} />
    //               </Routes>
    //               <Footer />
    //             </div>
    //           </Router>
    //         </Route>
    //       </Routes>
    //     </Router>
    //   </div>
    // );
    return (
      <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        {/* <Navbar /> */}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="home" element={<Home />} >
              {/* <Route path="resume" element={<Resume />} />
              <Route path="*" element={<Navigate to="/"/>} /> */}
          </Route>
          <Route path="map" element={<Resume />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        {/* <Footer /> */}
      </div>
    </Router>

      // <Router>
      //   <Preloader load={load} />
      //   <div style={{
      //     backgroundImage:`url(${image})`,
      //     backgroundPosition: 'center',
      //     backgroundRepeat: 'no-repeat',
      //     backgroundSize: 'cover',
      //     width: '100vw',
      //     height: '100vh'}}
      //     className="Login">
      //     <input
      //       id="userInput"
      //       type="text"
      //       placeholder="Password"
      //       className="input"
      //     />
      //     <button onClick="returnText()" className="button" >ENTER</button>
      //     <Route path="/home" element={<Home />} />
      //   </div>
      // </Router>
    );
}

export default App;


// import React, { useState, useEffect } from "react";
// import Preloader from "../src/components/Pre";
// import Navbar from "./components/Navbar";
// import Home from "./components/Home/Home";
// import About from "./components/About/About";
// import Projects from "./components/Projects/Projects";
// import Footer from "./components/Footer";
// import Resume from "./components/Resume/ResumeNew";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate
// } from "react-router-dom";
// import ScrollToTop from "./components/ScrollToTop";
// import "./style.css";
// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";

// function App() {
//   const [load, upadateLoad] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       upadateLoad(false);
//     }, 1200);

//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     <Router>
//       <Preloader load={load} />
//       <div className="App" id={load ? "no-scroll" : "scroll"}>
//         <Navbar />
//         <ScrollToTop />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/project" element={<Projects />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/resume" element={<Resume />} />
//           <Route path="*" element={<Navigate to="/"/>} />
//         </Routes>
//         <Footer />
//       </div>
//     </Router>
//   );
// }

// export default App;